import React from "react"
import { graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styled from "styled-components"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import Socials from "../components/socials"

const Container = styled.div`
  color: #D3D3D3;
  max-width: 1300px;
  margin: 0 auto;
  padding: 16px;
  margin-bottom: 64px;
  text-align: center;

  h1, h2 {
    color: #D3D3D3;
  }

  ul {
    margin: 0;
    list-style-type: none;

    li {
      margin: 0;
      margin: 8px;

      a {
        color: #D3D3D3;
        padding: 8px;
        text-decoration: underline;

        transition: 0.25s color;

        &:hover { 
          color: rgb(0, 173, 239);
        }
      }
    }
  }

  .socials {
    a {
      margin: 16px;
    }
    svg {
      margin: 0;
      font-size: 3em;
    }
  }
`

const NotFoundPage = ({ data }) => {
  const siteTitle = data.settingsPage.frontmatter.siteTitle

  return (
    <div title={siteTitle}>
      <SEO title="404: Page Not Found" />
      <Header data={data.settingsPage} />
      <Container>
        <h1>The Page you are looking for does not exist.</h1>
        <h2>Perhaps you were looking for one of the following:</h2>
        <ul>
          <div>
            <li><AnchorLink to="/#home" title="Home" stripHash /></li>
            <li><AnchorLink to="/#gallery" title="Gallery" stripHash /></li>
            <li><AnchorLink to="/#bio" title="Biography" stripHash /></li>
            <li><AnchorLink to="/#resume" title="Resume" stripHash /></li>
            <li><a href="https://www.corynovak.com">Blog</a></li>
            <li id="contactNav">
              <AnchorLink to="/#contact" title="Contact" stripHash />
            </li>
          </div>
        </ul>
        <h2>Or you can connect with Cory Novak:</h2>
        <Socials data={data.settingsPage} />
      </Container>
      <Footer data={data.settingsPage} />
    </div>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    settingsPage: markdownRemark(fields: { slug: { eq: "/settings/" } }) {
      frontmatter {
        siteTitle
        siteUrl
        description
        imdb
        twitter
        linkedin
        instagram
        facebook
        youtube
      }
    }
  }
`
